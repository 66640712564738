.sideDrawer, .sideDrawer .MuiPaper-root {
    width: 70px;
    transition: .2s;
}

.sideDrawer.opened, .sideDrawer.opened .MuiPaper-root {
    width: 240px;
}

.sideDrawer.opened .MuiListItemText-root {
    opacity: 1;
}

.sideDrawer .MuiListItemText-root {
    transition: .2s;
    opacity: 0;
}

.sideDrawer .logo-wrapper img {
    max-width: 220px;
    min-width: 220px;
}

.sideDrawer.opened .logo-wrapper img {
    max-width: 220px;
    min-width: 220px;
}

.logo-icon {
    object-fit: contain;
    height: 40px;
    max-width: 50px;
    min-width: 50px;
    margin: 0 10px;
    display: block;
}

.logo-full {
    object-fit: contain;
    margin: 0 10px;
    display: block;
    max-width: 220px;
    min-width: 220px;
}

.logo-wrapper {
    height: 52.5px;
}

.MuiTableCell-root.filters {
    padding: 5px 8px;
}

.myTable .MuiTableCell-root {
    padding: 8px 16px;
}

.myLink {
    cursor: pointer;
    border-bottom: 1px dashed;
    display: inline-block;
}