@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('fonts.css');

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;  
}
h2{
  font-size: 22px;
  
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sidebar-wrapper {
  width: 240px;
  background: #3639A4;
  min-width: 240px;
}

.logo img {
  filter: brightness(0) invert(1);
  width: 318px;
  object-fit: contain;
  height: 37px;
  padding: 6px;
}

.sidebar-item li {
  display: block;
}

.sidebar-item a {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 28px;
}

.sidebar-item li a:hover {
  background: rgba(255, 255, 255, 0.1);
  border-right: 5px solid lightblue;
}
